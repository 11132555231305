import React, { useEffect } from 'react'
import { graphql, navigateTo } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import MainContainer from '../../container/main'
import SEO from '../../components/seo'

import Container from '../../components/container'
import ContentHeroNew from '../../components/content-hero-new'
import ContentInlineNav from '../../components/content-inline-nav'
import ContentBlockLarge from '../../components/content-block-large'
import ContentBlockSmall from '../../components/content-block-small'
import ContentBannerWithCTA from '../../components/content-banner-with-cta'

import IconUsers from '../../assets/images/ic_users.svg'

// const IndexPage = () => {
//   return navigate('/')
// }

class IndexPage extends React.PureComponent {
  componentDidMount() {
    window.location.href = '/'
  }

  render() {
    return null
  }
}

// const IndexPage_BACKUP = ({ data }) => (
//   <MainContainer>
//     <SEO title="Basaas Workplace" />
//     <Container>
//       <ContentHeroNew
//         kicker="Optimize your app management"
//         headline="Drive efficiency with a unified digital workplace"
//         actionLinkText="Get started – It's free!"
//         actionLinkIsExternal
//         actionLink="https://workplace.basaas.com/register/?utm_source=basaas-com&utm_medium=hero&utm_campaign=signup&utm_content=home"
//         media={<StaticImage src="../../assets/images/workplace-hero.png" alt="Optimize your app management" placeholder="blurred" />}
//       />
//     </Container>
//     <Container style={{ zIndex: '2' }}>
//       <ContentInlineNav
//         kicker="Managing a team or company?"
//         headline="Learn how the Basaas Workplace can boost your company’s productivity"
//         // media={<IconTrust />}
//         secondary={
//           <div className="item">
//             <a className="bas__platform-btn bas__platform-download-app-btn" data-platform="win" href="#workplace-for-teams">
//               <IconUsers className="icon" />
//               All benefits for teams
//             </a>
//           </div>
//         }
//       />
//     </Container>
//     {/* Block Workplace USER */}
//     <Container withBackground style={{ padding: '80px 0' }}>
//       <ContentBlockLarge
//         kicker="Your daily business in one solution"
//         headline="Unite all apps into your personal productivity hub"
//         description="Connect all your favorite apps like Google Workspaces, Microsoft Teams, Slack, Asana and many more to your tailor-made integrated solution."
//         // actionLinkText="Learn more"
//         // actionLink="/workplace"
//         media={<StaticImage src="../../assets/images/workplace-user.png" alt="Optimize your app management" placeholder="blurred" />}
//       />
//       <Container columns={2} style={{ marginTop: '-48px' }}>
//         <ContentBlockSmall
//           headline="Work across apps everywhere"
//           description="Work across apps with any device and at any location. Switch between all your apps quickly and launch any app with a single click. Use the Basaas Chrome Extension to really work across SaaS solutions."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/multi-device.png" alt="Work across apps everywhere" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Simplify login to your apps"
//           description="Simplify your daily login to all apps and store your passwords securely within the Basaas Password Manager. Keep all your personal passwords perfectly organized and log in to your services from anywhere."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/password-manager.png" alt="Simplify login to your apps" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Organize your daily agenda"
//           description="Basaas My Day supports you to organize your daily agenda. Find your today's events, your focus tasks and all notifications at a glance. See your next appointment and start every online meeting with one click."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/organize-your-daily-agenda.png" alt="Organize your daily agenda" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Find everything cross app"
//           description="Find information faster with an overarching search which looks up your connected apps like Slack, Outlook etc. Find all data in a single place and on any device and view details with one click."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/find-every-thing-cross-app.png" alt="Find everything cross app" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Work remote on any device"
//           description="Provide access to all apps, data and information in one place and simplify remote work with a device-independent workplace."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/work-with-data-cross-app.png" alt="Work remote on any device" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Work with your data cross-app"
//           description="Always keep an overview of your tasks, events, contacts and more. Work cross-app with your most relevant data directly on your workplace. You can view, edit or add data of your apps in widgets."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/notifications-at-a-glance.png" alt="Work with your data cross-app" placeholder="blurred" />}
//         />
//       </Container>
//     </Container>
//     {/* Block Workplace MANAGER */}
//     <Container withBackground style={{ padding: '80px 0' }} id="workplace-for-teams">
//       <ContentBlockLarge
//         kicker="Your tailor-made best of breed solution"
//         headline="Combine all apps into one integrated enterprise solution"
//         description="Create an overarching enterprise solution and integrate your existing cloud services like Microsoft 365 or Google Workspace."
//         // actionLinkText="Learn more"
//         // actionLink="/workplace"
//         media={<StaticImage src="../../assets/images/workplace-manager.png" alt="Optimize your app management" placeholder="blurred" />}
//         reverse
//       />
//       <Container columns={2} style={{ marginTop: '-48px' }}>
//         <ContentBlockSmall
//           headline="Bring your entire daily business in one place"
//           description="Support every colleague with an easy-to-use digital workplace. Provide your employees access to all apps, data and information centralized in one place. A dashboard with widgets provides unified data interaction and the “My Day” agenda helps focus on today’s actions."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/all-apps-one-solution.png" alt="Bring your entire daily business in one place" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Work remote and on any device"
//           description="Basaas Workplace is the perfect foundation for remote work. Basaas is right there where your employees work. Basaas is available as web version, as an extension for Chrome, mobile on any device or as a native app for Windows and Mac."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/multi-device.png" alt="xxx" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Manage your SaaS users in one place"
//           description="Distribute your apps for teams, departments, or individual users. Roll-out your company apps or share widgets (data views), contents and predefined dashboards for colleagues. Login and access management via Active Directory and single sign on simplifies administration."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/manage-saas-users-in-one-place.png" alt="Manage your SaaS users in one place" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Enable easy login with a built-in password"
//           description="Simplify access to all apps by enabling each employee to keep their passwords organized in one safe place. Every user gets an own locally stored vault for safeguarding passwords with banking standard AES 256-bit encryption."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/password-manager.png" alt="Enable easy login with a built-in password" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Share any app or website without effort"
//           description="Manage all your corporate apps in one place. Access a comprehensive portfolio of apps and choose from 500+ world’s leading apps while being able to add internal company solutions as well."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/share-any-app-without-effort.png" alt="Share any app or website without effort" placeholder="blurred" />}
//         />
//         <ContentBlockSmall
//           headline="Analyze and optimize SaaS usage"
//           description="Gain meaningful insights into your company’s app usage, enhance IT security and manage Login and access management. A centralized app management creates transparency and reduces shadow IT."
//           // actionLinkText="Learn more"
//           // actionLink="/workplace"
//           media={<StaticImage src="../../assets/feature-icons/analyze-optimize-saas-usage.png" alt="Analyze and optimize SaaS usage" placeholder="blurred" />}
//         />
//       </Container>
//     </Container>

//     <Container style={{ padding: '80px 0' }}>
//       <ContentBannerWithCTA />
//     </Container>
//   </MainContainer>
// )

export default IndexPage

// export const query = graphql`
//   query {
//     placeholderLandscape: file(relativePath: { eq: "placeholder-landscape.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderPortrait: file(relativePath: { eq: "placeholder-portrait.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FULL_WIDTH, quality: 84)
//       }
//     }

//     placeholderSquare: file(relativePath: { eq: "placeholder-square.png" }) {
//       childImageSharp {
//         gatsbyImageData(layout: FIXED, width: 200, quality: 84)
//       }
//     }
//   }
// `
